import { useContext } from 'react';
import { TranslationContext } from './TranslationContext';

export const useTranslation = (path) => {
  const context = useContext(TranslationContext);

  if (path) {
    return (subPath) => {
      const translation = context?.translations?.[path]?.[subPath];
      if (!translation) {
        return `Translation missing for '${path}.${subPath}'`;
      }

      return translation;
    };
  }

  return context;
};
