import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TranslationContext } from '../../hooks/useTranslation/TranslationContext';

export const TranslationProvider = ({ translations, ...props }) => {
  const [currentLocale, setCurrentLocale] = useState('en');

  return (
    <TranslationContext.Provider
      {...props}
      value={{
        translations: translations[currentLocale] || {},
        setLocale: setCurrentLocale,
        locale: currentLocale,
      }}
    />
  );
};

TranslationProvider.propTypes = {
  translations: PropTypes.shape({}),
};

TranslationProvider.defaultProps = {
  translations: {},
};
