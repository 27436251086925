import React from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { TranslationProvider } from '@src/abyss/web/ui/TranslationProvider';
import { Routes } from './routes';

const theme = createTheme('uhc');

const translations = {
  en: {
    gated: {
      title: 'Get started by choosing a Primary Care Provider',
      chooseLabel: 'Choose a PCP',
      chooseInfo:
        "As part of your new plan, connecting with a Primary Care Provider (PCP) is key. They will be your first stop for care and will guide you through regular preventive care like annual checkups, shots and screenings, as well as refer you to any specialists as needed. See them first to make sure you don't end up paying more than you need. Don't want to choose a PCP now or don't have one in particular in mind? We have you covered. We will match you with a quality network provider near you a month after your active date.",
      scheduleLabel: 'Schedule an appointment',
      scheduleInfo:
        'See your PCP after your plan is effective. Your PCP will provide you with a variety of services that can help you get and stay healthy. You can schedule an appointment to get recovery care, coordinate care across multiple doctors, manage chronic conditions, referrals to specialty care, flu shots and other preventive care, annual exams, prescription refills.',
      rewardLabel: 'Earn reward',
      rewardInfo:
        'When you complete a visit with your PCP, it gets you one step closer to earning your $100 reward as part of your new plan.',
      button: 'Choose PCP',
    },
    nongated: {
      title: 'Get started by choosing a Primary Care Provider',
      chooseLabel: 'Choose a PCP',
      chooseInfo:
        "As part of your new plan, connecting with a Primary Care Provider (PCP) is key. They will be your first stop for care and will guide you through regular preventive care like annual checkups, shots and screenings. See them first to make sure you don't end up paying more than you need. Don't want to choose a PCP now or don't have one in particular in mind? We have you covered. We will match you with a quality network provider near you a month after your active date.",
      scheduleLabel: 'Schedule an appointment',
      scheduleInfo:
        'See your PCP after your plan is effective. Your PCP will provide you with a variety of services that can help you get and stay healthy. You can schedule an appointment to get recovery care, coordinate care across multiple doctors, manage chronic conditions, flu shots and other preventive care, annual exams, prescription refills.',
      rewardLabel: 'Earn reward',
      rewardInfo:
        'When you complete a visit with your PCP, it gets you one step closer to earning your $100 reward as part of your new plan.',
      button: 'Select PCP',
    },
    thankyou: {
      title: 'Primary Care Provider confirmation',
      confirm: "You're all set",
      heading: 'Your Primary Care Provider',
      nextLabel: 'What to expect next?',
      nextInfo:
        'Make sure to pay your initial payment if you have not already. When that is processed, expect your welcome mailing with ID card in the mail (usually 2-3 weeks). Once you receive that, get started by registering on myuhc.com/exchange where you can manage your plan by finding in network care, paying bills, and getting set up for paperless.',
    },
    error: {
      error: "Sorry, we're experiencing technical difficulties accessing this page content. Please try again. If you continue to experience issues, please call 1-844-368-5877."
    }
  },
  es: {
    gated: {
      title: 'Comience eligiendo un Proveedor de Atención Primaria',
      chooseLabel: 'Elija un PAP',
      chooseInfo:
        'Como parte de su nuevo plan, la conexión con un Proveedor de Atención Primaria (PAP) es clave. Serán su primera parada para recibir atención, lo guiarán a través de la atención preventiva regular, como controles anuales, vacunas y exámenes, y lo derivarán a cualquier especialista según sea necesario. Échales un vistazo primero para asegurarte de no terminar pagando más de lo necesario. ¿No quiere elegir un PAP ahora o no tiene uno en particular en mente? Te tenemos cubierto. Lo conectaremos con un proveedor de red de calidad cerca de usted un mes después de su fecha de activación.',
      scheduleLabel: 'Agendar una cita',
      scheduleInfo:
        'Verifique su PAP después de que su plan entre en vigencia. Su PAP le brindará una variedad de servicios que pueden ayudarlo a estar y mantenerse saludable. Puede programar una cita para obtener atención de recuperación, coordinar la atención entre varios médicos, controlar afecciones crónicas, remisiones a atención especializada, vacunas contra la gripe y otra atención preventiva, exámenes anuales, recargas de recetas.',
      rewardLabel: 'Ganar recompensa',
      rewardInfo:
        'Cuando completa una visita con su PAP, lo acerca un paso más a ganar su recompensa de $100 como parte de su nuevo plan.',
      button: 'Elija PAP',
    },
    nongated: {
      title: 'Comience eligiendo un Proveedor de Atención Primaria',
      chooseLabel: 'Elija un PAP',
      chooseInfo:
        'Como parte de su nuevo plan, la conexión con un Proveedor de Atención Primaria (PAP) es clave. Serán su primera parada para recibir atención y lo guiarán a través de la atención preventiva regular, como chequeos anuales, inmunizaciones y exámenes de detección. Échales un vistazo primero para asegurarte de no terminar pagando más de lo necesario. ¿No quiere elegir un PAP ahora o no tiene uno en particular en mente? Te tenemos cubierto. Lo conectaremos con un proveedor de red de calidad cerca de usted un mes después de su fecha de activación.',
      scheduleLabel: 'Agendar una cita',
      scheduleInfo:
        'Verifique su PAP después de que su plan entre en vigencia. Su PAP le brindará una variedad de servicios que pueden ayudarlo a estar y mantenerse saludable. Puede programar una cita para obtener atención de recuperación, coordinar la atención entre varios médicos, controlar afecciones crónicas, vacunas contra la gripe y otra atención preventiva, exámenes anuales, recargas de recetas.',
      rewardLabel: 'Ganar recompensa',
      rewardInfo:
        'Cuando completa una visita con su PAP, lo acerca un paso más a ganar su recompensa de $100 como parte de su nuevo plan.',
      button: 'Seleccionar PAP',
    },
    thankyou: {
      title: 'Confirmación del Proveedor de Atención Primaria',
      confirm: 'Ya estás listo',
      heading: 'Su Proveedor de Atención Primaria',
      nextLabel: '¿Qué esperar a continuación?',
      nextInfo:
        'Asegúrese de pagar su pago inicial si aún no lo ha hecho. Cuando se procese, espere su carta de bienvenida con tarjeta de identificación por correo (generalmente 2-3 semanas). Una vez que lo reciba, comience por registrarse en myuhc.com/exchange, donde puede administrar su plan buscando atención en línea, pagando facturas y configurando un servicio sin papel.',
    },
    error: {
      error: "Lo sentimos, estamos experimentando dificultades técnicas para acceder al contenido de esta página. Inténtalo de nuevo. Si continúa teniendo problemas, llame al 1-844-368-5877."
    }
  },
};

export const client = () => {
  return (
    <AbyssProvider theme={theme}>
      <TranslationProvider translations={translations}>
        <Routes />
      </TranslationProvider>
    </AbyssProvider>
  );
};
