import { useEffect } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';

export const ThankyouRoute = () => {
  const router = useRouter();
  const routeParams = router.getRouteParams();
  const { navigate } = useRouter();
  useEffect(() => {
    if (routeParams.token) {
      localStorage.setItem('payload_ty', routeParams.token);
      const payload = tokenizer.parse(routeParams.token);
      navigate('/thankyou', { state: payload });
    }
  }, [routeParams]);

  return null;
};
