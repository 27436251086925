import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { GatedHome } from '../components/Gated';
import { NongatedHome } from '../components/Nongated';
import { Thankyou } from '../components/Thankyou';
import { InBoundRoute } from './InBoundRoute';
import { ThankyouRoute } from './ThankyouRoute';
import { Error } from '../components/Error';
import { ErrorRoute } from './ErrorRoute';

export const Routes = () => {
  return (
    <Router.Routes>
      <Router.Route path="/inbound/:token" element={<InBoundRoute />} />
      <Router.Route path="/gated" element={<GatedHome />} />
      <Router.Route path="/nongated" element={<NongatedHome />} />
      <Router.Route path="/thankyou/:token" element={<ThankyouRoute />} />
      <Router.Route path="/thankyou" element={<Thankyou />} />
      <Router.Route path="/error" element={<Error />} />
      <Router.Route path="/error/apierror" element={<ErrorRoute />} />
    </Router.Routes>
  );
};
