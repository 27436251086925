import React from 'react';
import { Text } from '@abyss/web/ui/Text';
import { Box } from '@abyss/web/ui/Box';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';

import { useTranslation } from '../../abyss/web/hooks/useTranslation';
import { Header } from '../Header';

export const Error = () => {
  const t = useTranslation('error');

  const HeaderContainer = styled('div', {
    padding: '$xl',
    backgroundColor: '$gray2'
  });

  const Container = styled('div', {
    backgroundColor: '$primary2',
    height: '100vh',
  });

  return (
    <Container>
      <Header/>
      <Divider />
      <HeaderContainer>
        <Flex alignItems='center' justify='center'>
          <Layout.Group>
            <IconBrand icon='alert' size='$xl' />
            <Heading>Uh-oh!</Heading>
          </Layout.Group>
        </Flex>
      </HeaderContainer>
      <Divider />
      <Layout.Space space={20} />
      <Flex justify='center'>
        <Box width='85%' color='$primary2'>
          <Text size="$lg" fontWeight="bold">
            {t('error')}
          </Text>
        </Box>
      </Flex>
      <script type="text/javascript">_satellite.pageBottom();</script>
    </Container>
  );
};
