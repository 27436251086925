import { useEffect } from 'react';
import axios from 'axios';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { config } from '@abyss/web/tools/config';

export const InBoundRoute = () => {
  const DEV_URL= config('DEV_URL');
  const VALIDATION_ERROR_LOG_URL = DEV_URL+ config('API_VALIDATION_ERROR_LOG');
  const router = useRouter();
  const routeParams = router.getRouteParams();
  const {navigate} = useRouter();
  useEffect(() => {
    if (routeParams.token) {
      const {token} = routeParams;
      localStorage.setItem('payload', routeParams.token);
      const payload = tokenizer.parse(routeParams.token);
      const { gated , exchangeId, stateCode, qhpId, ndbLob, zipCode, coverageEffectiveDate } = payload;

      if (
        !exchangeId ||
        !stateCode ||
        !qhpId ||
        !ndbLob ||
        !zipCode ||
        !coverageEffectiveDate
      )  {
        axios.post(VALIDATION_ERROR_LOG_URL, {token}, {
          headers: {
            'Content-Type': 'application/json',
          },
        }).catch(()=>{
          navigate('/error');
        });
      } else if (gated === 'Y') {
        navigate('/gated', { state: payload });
      } else {
        navigate('/nongated', { state: payload });
      }
    }
  }, [routeParams]);

  return null;
};
