import React, { useState } from 'react';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { Flex } from '@abyss/web/ui/Flex';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { styled } from '@abyss/web/tools/styled';
import { useTranslation } from '../../abyss/web/hooks/useTranslation';

const HeaderContainer = styled('div', {
  padding: '$sm',
  backgroundColor: '$primary2',
});

const HeaderMargins = styled('div', {
  marginLeft: 75,
  marginRight: 80,
});

const MenuContainer = styled('div', {
  padding: '$sm',
});

const languages = {
  en: 'English',
  es: 'Español',
};

export const Header = (props) => {
  const { locale, setLocale } = useTranslation();
  const { payload } = props || {};
  const { language } = payload || {};
  const transactionId = payload?.txnid;
  const [currentLanguage, setLanguage] = useState(language);

  if (currentLanguage === 'en') {
    setLocale('en');
  } else if (currentLanguage === 'es') {
    setLocale('es');
  } else if (transactionId?.endsWith('en')) {
    setLocale('en');
  } else if (transactionId?.endsWith('es')) {
    setLocale('es');
  }

  const menuItems = [
    {
      title: 'Language',
      checkboxes: [
        {
          value: 'en',
          label: languages.en,
          onChange: () => {
            setLocale('en');
            setLanguage('en');
          },
        },
        {
          value: 'es',
          label: languages.es,
          onChange: () => {
            setLocale('es');
            setLanguage('es');
          },
        },
      ],
    },
  ];

  return (
    <div>
      <HeaderContainer>
        <HeaderMargins>
          <Flex justify="space-between" alignItems="center">
          <Brandmark
            size="$md"
            affiliate="uhc"
            variant="lockup"
            color="blue"
            brand="uhc"
          />
          <MenuContainer>
            <React.Fragment>
              <DropdownMenu
                outline="true"
                label={languages[locale]}
                after={<IconMaterial icon="arrow_drop_down" />}
                menuItems={menuItems}
              />
            </React.Fragment>
          </MenuContainer>
        </Flex>
      </HeaderMargins>
    </HeaderContainer>
    </div>
  );
};
