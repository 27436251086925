import React, {useEffect, useState } from 'react';
import { Text } from '@abyss/web/ui/Text';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { useTranslation } from '../../abyss/web/hooks/useTranslation';
import { ChoosePCP } from '../RallyIntegration/ChoosePCP';
import { Header } from '../Header';

const TitleContainer = styled('div', {
  padding: '$xl',
  backgroundColor: '$primary1',
});

const TitleMargin = styled('div', {
  marginLeft: 35,
});

const BodyContainer = styled('div', {
  marginLeft: 80,
  width: '59%',
});

const Container = styled('div', {
  backgroundColor: '$primary2',
  height: '100vh',
});

export const NongatedHome = () => {
  const t = useTranslation('nongated');
  const {getRouteParams} = useRouter();
  const { token } = getRouteParams();
  const [payload, setPayload] = useState(tokenizer.parse(token));
  useEffect(() => {
  if (!payload) {
    setPayload(tokenizer.parse(localStorage.getItem('payload')));
  }
}, []);
  return (
    <Container>
      <Header payload={payload} />
      <TitleContainer>
        <TitleMargin>
          <Heading color="$white">{t('title')}</Heading>
        </TitleMargin>
      </TitleContainer>
      <Layout.Space space={45} />
      <BodyContainer>
        <Layout.Group
          space={20}
          alignItems="top"
          style={{ flexFlow: 'nowrap' }}
        >
          <Layout.Stack>
            <Layout.Space space={0} />
            <IconBrand size={60} icon="stethoscope" />
          </Layout.Stack>
          <Layout.Stack alignItems="left" space={0}>
            <Heading offset={2}>
                {t('chooseLabel')}
            </Heading>
            <Layout.Stack alignItems="left" space={15}>
              <Text color='$gray6'>{t('chooseInfo')}</Text>
              <ChoosePCP payload={payload} />
            </Layout.Stack>
          </Layout.Stack>
        </Layout.Group>
        <Layout.Space space={25} />
        <Layout.Group
          space={20}
          alignItems="top"
          style={{ flexFlow: 'nowrap' }}
        >
        <Layout.Stack>
          <Layout.Space space={0} />
          <IconBrand size={60} icon="calendar" />
        </Layout.Stack>
          <Layout.Stack alignItems="left" space={0}>
            <Heading offset={2}>
                {t('scheduleLabel')}
            </Heading>
            <Text color='$gray6'>{t('scheduleInfo')}</Text>
          </Layout.Stack>
        </Layout.Group>
      </BodyContainer>
      <script type="text/javascript">_satellite.pageBottom();</script>
    </Container>
  );
};